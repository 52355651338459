import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Form,
  Button,
  Toast,
  Container,
  Row,
  Modal,
  Col,
} from "react-bootstrap";
import MenuBar from "../MenuBar";
import { FiWifi, FiWifiOff } from "react-icons/fi";
import { FaTrash, FaSortAmountDownAlt } from "react-icons/fa";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { DEVICE_SERVER } from "../../Config";
import { USER_SERVER } from "../../Config";
import NavBar from "../NavBar";

import { EditDoc } from "./EditDoc";
import { ProductList } from "./ProductList";
import { Dump } from "./Dump";
import { DeviceTypes } from "./DeviceTypes";
import moment from "moment";

import {
  getWs,
  registerEventHandler,
  unRegisterEventHandler,
} from "../../../common";

export const AdminareaPage = () => {
  const [userlist, setUserList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [showDeleteOneUserModal, setShowDeleteOneUserModal] = useState(false);
  const [showDeleteSelectedUsersModal, setShowDeleteSelectedUsersModal] =
    useState(false);
  const [deletingUserRow, setDeletingUserRow] = useState([]);
  // const [deletedUserLength, setDeletedUserLength] = useState(0);
  let disabledUserSelected = selectedUserList.length < 1 ? true : false;

  const [showUserToast, setShowUserToast] = useState(false);
  const [toastText, setToastText] = useState("");

  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");

  const [deviceList, setDeviceList] = useState([]);
  const [deviceListForTable, setDeviceListForTable] = useState([]);
  const [selectedDevicesList, setSelectedDevicesList] = useState([]);
  const [showDeleteOneDeviceModal, setShowDeleteOneDeviceModal] =
    useState(false);
  const [showDeleteSelectedDevicesModal, setShowDeleteSelectedDevicesModal] =
    useState(false);
  const [deletingDeviceRow, setDeletingDeviceRow] = useState([]);
  const [userSortValue, setUserSortValue] = useState("asc");
  const [userSortField, setUserSortField] = useState("firstname");

  const [devSortValue, setDevSortValue] = useState("asc");
  const [devSortField, setDevSortField] = useState("devid");

  let disabledDeviceSelected = selectedDevicesList.length < 1 ? true : false;
  let user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    getDeviceList();
    getUserList();

    if (window.innerWidth < 981) {
      setMainClass("body_panel col-sm-12");
      setPropShowMenu(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line
  }, [userSortField, userSortValue]);

  useEffect(() => {
    getDeviceList();
    // eslint-disable-next-line
  }, [devSortField, devSortValue]);
  /////////////////////////////////////////////// User Table ////////////////////////////////////////////////////////////////

  const getUserList = () => {
    axios
      .post(`${USER_SERVER}/getallusers`)
      .then((data) => {
        if (data.data.success) {
          let tempUsers = data.data.users;

          // sum all network usage for each user
          tempUsers.forEach(user => {
            user.networkUsage = user.devices.map(a => a.networkUsage).reduce((a,b) => a + b, 0);
          });

          if (userSortField === "firstName" && userSortValue === "asc")
            tempUsers.sort((a, b) =>
              ("" + a.firstname).localeCompare(b.firstName)
            );
          else if (userSortField === "firstName" && userSortValue === "desc")
            tempUsers.sort((a, b) =>
              ("" + b.firstname).localeCompare(a.firstName)
            );
          else if (userSortField === "lastName" && userSortValue === "asc")
            tempUsers.sort((a, b) =>
              ("" + a.lastname).localeCompare(b.lastName)
            );
          else if (userSortField === "lastName" && userSortValue === "desc")
            tempUsers.sort((a, b) =>
              ("" + b.lastname).localeCompare(a.lastName)
            );
          setUserList(tempUsers);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const idHeaderFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        User ID
        <FaSortAmountDownAlt />
      </div>
    );
  };

  const datesignupFormatter = (cell, row, rowIndex, formatExtraData) => {
    let createdAt = new Date(cell);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let date = createdAt.getDate();
    let month = monthNames[createdAt.getMonth()];
    let year = createdAt.getFullYear();

    return (
      <span>
        {date} {month} {year}{" "}
      </span>
    );
  };

  const memberforFormatter = (cell, row, rowIndex, formatExtraData) => {
    let createdAt = new Date(row.createdAt);
    let current = new Date();
    let start = moment(current);
    let end = moment(createdAt);
    let memberFor = "";

    if (start.diff(end, "days") === 0) {
      memberFor = "today";
    } else if (start.diff(end, "days") < 30) {
      memberFor = start.diff(end, "days") + " days";
    } else if (start.diff(end, "months") < 12) {
      memberFor = start.diff(end, "months") + " months";
    } else {
      memberFor = start.diff(end, "years") + " years";
    }

    return <span>{memberFor} </span>;
  };

  const userNetworkFormatter = (cell, row, rowIndex, formatExtraData) => {
    //var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    var sizes = ["KB", "MB", "GB", "TB"];
    if (cell === 0 || cell === null || cell === undefined) return "0 KB";
    var i = parseInt(Math.floor(Math.log(cell) / Math.log(1024)));
    return Math.round(cell / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button variant="outline-secondary" className="actionBtn">
        <FaTrash />
      </Button>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "User ID",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      headerFormatter: idHeaderFormatter,
    },
    {
      dataField: "firstName",
      text: "First name",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "lastName",
      text: "Last name",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "company",
      text: "Company",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "province",
      text: "Province/State",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "zipCode",
      text: "Postal/Zip code",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "mobileNumber",
      text: "Mobile number",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "telephoneNumber",
      text: "Tel number",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "createdAt",
      text: "Date sign up",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      formatter: datesignupFormatter,
    },
    {
      dataField: "createdAta",
      text: "Member for",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      formatter: memberforFormatter,
    },
    // {
    //   dataField: "storagedata",
    //   text: "Storage Data",
    //   sort: true,
    //   headerStyle: { color: "#F47721", cursor: "pointer" },
    //   formatter: userNetworkFormatter,
    // },
    {
      dataField: "networkUsage",
      text: "Network Usage",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      formatter: userNetworkFormatter,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setShowDeleteOneUserModal(true);
          setDeletingUserRow(row);
        },
      },
    },
  ];

  const selectRow = {
    mode: "checkbox",
    bgColor: "#EAF6FC",
    style: { backgroundColor: "red" },
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedUserList((selectedUserList) => {
        if (isSelect) {
          return [...selectedUserList, row];
        } else {
          let k = selectedUserList.findIndex((temp) => temp.id === row.id);
          return [
            ...selectedUserList.slice(0, k),
            ...selectedUserList.slice(k + 1, selectedUserList.length),
          ];
        }
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelectedUserList((selectedUserList) => {
        if (isSelect) {
          return [...userlist];
        } else {
          return [];
        }
      });
    },
  };

  const pnoptions = {
    hideSizePerPage: true,
  };

  const onClickDeleteUser = () => {
    axios
      .post(`${USER_SERVER}/permdeleteuser`, {
        userid: [deletingUserRow.id],
      })
      .then((data) => {
        if (data.data.success) {
          //state right stone
          setUserList((userlist) => {
            let k = userlist.findIndex(
              (user) => user.id === deletingUserRow.id
            );
            if (k > -1) {
              return [
                ...userlist.slice(0, k),
                ...userlist.slice(k + 1, userlist.length),
              ];
            } else {
              return userlist;
            }
          });
          // setDeletedUserLength(1);
          setShowDeleteOneUserModal(false);

          setToastText("1 user deleted.");
          setShowUserToast(true);
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickDeleteSelectedUsers = () => {
    let userids = [];
    selectedUserList.forEach((row) => {
      userids.push(row.id);
    });

    axios
      .post(`${USER_SERVER}/permdeleteuser`, {
        userid: userids,
      })
      .then((data) => {
        if (data.data.success) {
          getUserList();
          // setDeletedUserLength(userids.length);
          setShowDeleteSelectedUsersModal(false);
          setShowUserToast(true);
          setToastText(userids.length + " users deleted.");
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };
  const onCloseUserToast = () => {
    setShowUserToast(false);
    // setDeletedUserLength(0);
  };

  const onConfirmDeleteSelectedUsers = () => {
    setShowDeleteSelectedUsersModal(true);
  };

  /////////////////////////////////////////////// Device list ////////////////////////////////////////////////////////////////

  useEffect(() => {
    //  getWebSocket();
    let ws = getWs();
    if (ws !== null) {
      const browserId = window.localStorage.getItem("browserId");
      const userId = user.id;

      let deviceIdArray = [];
      deviceList.forEach((device) => {
        deviceIdArray.push(device.id);
      });

      let json = {
        senderType: "browser",
        userId: userId,
        browserId: browserId,
        receiver: "server",
        deviceIds: deviceIdArray,
        message: "registerBrowser",
        page: "devicelist",
      };

      registerEventHandler("devicelist", onmessage, json);
    }

    return () => {
      unRegisterEventHandler("devicelist");
    };
    // eslint-disable-next-line
  }, [deviceList]);

  const getDeviceList = () => {
    axios
      .post(`${DEVICE_SERVER}/getalldevices`)
      .then((data) => {
        if (data.data.success) {
          let tempDevices = data.data.devices;
          if (devSortField === "devid" && devSortValue === "asc")
            tempDevices.sort((a, b) =>
              ("" + a.deviceCode).localeCompare(b.deviceCode)
            );
          else if (devSortField === "devid" && devSortValue === "desc")
            tempDevices.sort((a, b) =>
              ("" + b.deviceCode).localeCompare(a.deviceCode)
            );
          else if (devSortField === "owner" && devSortValue === "asc")
            tempDevices.sort(
              (a, b) => parseFloat(a.owner.firstName) - parseFloat(b.owner.firstName)
            );
          else if (devSortField === "owner" && devSortValue === "desc")
            tempDevices.sort(
              (a, b) => parseFloat(b.owner.firstName) - parseFloat(a.owner.firstName)
            );

          let tempDeviceListForTable = JSON.parse(JSON.stringify(tempDevices));
          for (let i in tempDevices) {
            tempDeviceListForTable[i].deviceStatus = "offline";
          }
          setDeviceList(tempDevices);
          setDeviceListForTable(tempDeviceListForTable);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onmessage = (res) => {
    if (deviceList.length < 1) return;

    // Handle server message
    if (res.message === "registerBrowser") {
      let status = res.status;
      let tempDeviceListForTable = JSON.parse(JSON.stringify(deviceList));
      for (let i in deviceList) {
        tempDeviceListForTable[i].deviceStatus =
          status[tempDeviceListForTable[i].id];
      }

      setDeviceListForTable(tempDeviceListForTable);
    } else if (res.message === "deviceConnected") {
      console.log("deviceConnected");
    } else if (res.message === "deviceDisconnected") {
      console.log("deviceDisconnected");
    } else if (res.message === "deviceStatus") {
      getDeviceList();
    }
  };

  const idDeviceHeaderFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        ID
        <FaSortAmountDownAlt />
      </div>
    );
  };

  const statusDeviceFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (cell === "online") {
      return (
        <FiWifi style={{ color: "#27AE60", width: "16px", height: "16px" }} />
      );
    } else if (cell === "offline") {
      return (
        <FiWifiOff
          style={{ color: "#BDBDBD", width: "16px", height: "16px" }}
        />
      );
    }
  };

  const ownerDeviceFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        {row.owner.firstName} {row.owner.lastName}
      </div>
    );
  };

  const sharedDeviceFormatter = (cell, row, rowIndex, formatExtraData) => {
    let sharedCount = cell === null ? 0 : cell;
    return <div>{sharedCount}</div>;
  };

  // const storageFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   let data = row.networkusage;
  //   var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  //   if (data === 0 || data === null) return "0 Byte";
  //   var i = parseInt(Math.floor(Math.log(data) / Math.log(1024)));
  //   return Math.round(data / Math.pow(1024, i), 2) + " " + sizes[i];
  // };

  const networkFormatter = (cell, row, rowIndex, formatExtraData) => {
    //var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    var sizes = ["KB", "MB", "GB", "TB"];
    if (cell === 0 || cell === null) return "0 KB";
    var i = parseInt(Math.floor(Math.log(cell) / Math.log(1024)));
    return Math.round(cell / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  const columnsDevice = [
    {
      dataField: "deviceStatus",
      text: "Status",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      formatter: statusDeviceFormatter,
    },
    {
      dataField: "deviceCode",
      text: "Device ID",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      headerFormatter: idDeviceHeaderFormatter,
    },
    {
      dataField: "deviceName",
      text: "Name",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "model",
      text: "Model",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "localIp",
      text: "Local IP address",
    },
    {
      dataField: "owner",
      text: "Owner",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      formatter: ownerDeviceFormatter,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    // {
    //   dataField: "province",
    //   text: "Province/State",
    // },
    {
      dataField: "firmware",
      text: "Firmware",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
    },
    {
      dataField: "sharedCount",
      text: "Shared",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      formatter: sharedDeviceFormatter,
    },
    {
      dataField: "createdAt",
      text: "1st time used",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      formatter: datesignupFormatter,
    },
    {
      dataField: "memberfor",
      text: "Device active for",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      formatter: memberforFormatter,
    },
    // {
    //   dataField: "storagedata",
    //   text: "Storage Data",
    //   sort: true,
    //   headerStyle: { color: "#F47721", cursor: "pointer" },
    //   formatter: storageFormatter,
    // },
    {
      dataField: "networkUsage",
      text: "Network Data",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      formatter: networkFormatter,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setShowDeleteOneDeviceModal(true);
          setDeletingDeviceRow(row);
        },
      },
    },
  ];

  const selectRowDevice = {
    mode: "checkbox",
    bgColor: "#EAF6FC",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedDevicesList((selectedDevicesList) => {
        if (isSelect) {
          return [...selectedDevicesList, row];
        } else {
          let k = selectedDevicesList.findIndex((temp) => temp.id === row.id);
          return [
            ...selectedDevicesList.slice(0, k),
            ...selectedDevicesList.slice(k + 1, selectedDevicesList.length),
          ];
        }
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelectedDevicesList((selectedDevicesList) => {
        if (isSelect) {
          return [...deviceListForTable];
        } else {
          return [];
        }
      });
    },
  };

  const onClickDeleteDevice = () => {
    axios
      .post(`${DEVICE_SERVER}/deletedevice`, {
        deviceid: deletingDeviceRow.id,
      })
      .then((data) => {
        if (data.data.success) {
          getDeviceList();
          setShowDeleteOneDeviceModal(false);

          setToastText("1 device deleted.");
          setShowUserToast(true);
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickDeleteSelectedDevices = () => {
    let deviceids = [];
    selectedDevicesList.forEach((row) => {
      deviceids.push(row.id);
    });

    axios
      .post(`${DEVICE_SERVER}/deletedevice`, {
        deviceid: deviceids,
      })
      .then((data) => {
        if (data.data.success) {
          getDeviceList();
          // setDeletedUserLength(userids.length);
          setShowDeleteSelectedDevicesModal(false);
          setShowUserToast(true);
          setToastText(deviceids.length + " devices deleted.");
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onConfirmDeleteSelectedDevices = () => {
    setShowDeleteSelectedDevicesModal(true);
  };

  ///////////////////////////////////////////////// Device notes ////////////////////////////////////

  //////////////////////////// Hamburger menu  ////////////////////////////////////////////////
  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
      ? setMainClass("body_panel col-sm-10")
      : setMainClass("body_panel col-sm-12");
  };

  const onChangeUserSortValue = (e) => {
    setUserSortValue(e.target.value);
  };

  const onChangeUserSortField = (e) => {
    setUserSortField(e.target.value);
  };

  const onChangeDevSortValue = (e) => {
    setDevSortValue(e.target.value);
  };

  const onChangeDevSortField = (e) => {
    setDevSortField(e.target.value);
  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger} />
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
      <div className="mainpanel container-fluid">
        <div className="row">
          {propShowMenu ? <MenuBar /> : null}

          <Toast
            delay={3000}
            autohide
            show={showUserToast}
            onClose={() => {
              onCloseUserToast();
            }}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              paddingLeft: 20,
              zIndex: 8,
              width: 400,
              borderTop: "4px solid #F47721",
            }}
          >
            {/* <Toast.Body>{deletedUserLength} users deleted.</Toast.Body> */}
            <Toast.Body>{toastText}</Toast.Body>
          </Toast>

          <div className={mainClass}>
            <div className="page_title">Admin area</div>
            <div className="page_content">
              <Tabs defaultActiveKey="userlist" transition={false}>
                <Tab
                  eventKey="userlist"
                  title="User list"
                  tabClassName="sub_menus"
                >
                  <div className="sub_content">
                    <div className="sub_title">User list</div>
                    <div className="sub_body">
                      <Form.Group>
                        <Button
                          variant="outline-dark"
                          className="controlBtn"
                          disabled={disabledUserSelected}
                          onClick={() => {
                            onConfirmDeleteSelectedUsers();
                          }}
                        >
                          <FaTrash style={{ marginRight: "6px" }} />
                          Delete selected
                        </Button>
                      </Form.Group>

                      <Form.Group className="sortArea">
                        <Row>
                          <Col
                            xs={2}
                            style={{ textAlign: "right", padding: "8px 0px" }}
                          >
                            <Form.Label>Sort by </Form.Label>
                          </Col>
                          <Col xs={4}>
                            <Form.Control
                              onChange={(e) => {
                                onChangeUserSortField(e);
                              }}
                              as="select"
                            >
                              <option value="firstname">First name</option>
                              <option value="lastname">Last name</option>
                            </Form.Control>
                          </Col>
                          <Col xs={4}>
                            <Form.Control
                              onChange={(e) => {
                                onChangeUserSortValue(e);
                              }}
                              as="select"
                            >
                              <option value="asc">Ascending</option>
                              <option value="desc">Descending</option>
                            </Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>
                      <BootstrapTable
                        keyField="id"
                        data={userlist}
                        columns={columns}
                        bordered={false}
                        selectRow={selectRow}
                        striped={true}
                        pagination={paginationFactory(pnoptions)}
                      />
                      <Modal
                        show={showDeleteOneUserModal}
                        onHide={() => {
                          setShowDeleteOneUserModal(false);
                        }}
                      >
                        <Modal.Header
                          closeButton
                          style={{ borderBottom: "none" }}
                        >
                          <Modal.Title>Delete user</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Row>
                              <p>
                                Are you sure want to PERMANENTLY delete user "
                                {deletingUserRow.firstName}{" "}
                                {deletingUserRow.lastName}"?
                                <br />
                                This action can't be undone.
                              </p>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                              <Button
                                className="normalButton"
                                onClick={() => {
                                  onClickDeleteUser();
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                className="cancelButton"
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  setShowDeleteOneUserModal(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>

                      <Modal
                        show={showDeleteSelectedUsersModal}
                        onHide={() => {
                          setShowDeleteSelectedUsersModal(false);
                        }}
                      >
                        <Modal.Header
                          closeButton
                          style={{ borderBottom: "none" }}
                        >
                          <Modal.Title>Delete selected users</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Row>
                              <p>
                                Are you sure want to PERMANENTLY delete{" "}
                                {selectedUserList.length} users?
                                <br />
                                This action can't be undone.
                              </p>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                              <Button
                                className="normalButton"
                                onClick={() => {
                                  onClickDeleteSelectedUsers();
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                className="cancelButton"
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  setShowDeleteSelectedUsersModal(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="devlist"
                  title="Device list"
                  tabClassName="sub_menus"
                >
                  <div className="sub_content">
                    <div className="sub_title">Device list</div>
                    <div className="sub_body">
                      <Form.Group>
                        <Button
                          variant="outline-dark"
                          className="controlBtn"
                          disabled={disabledDeviceSelected}
                          onClick={() => {
                            onConfirmDeleteSelectedDevices();
                          }}
                        >
                          <FaTrash style={{ marginRight: "6px" }} />
                          Delete selected
                        </Button>
                      </Form.Group>
                      <Form.Group className="sortArea">
                        <Row>
                          <Col
                            xs={2}
                            style={{ textAlign: "right", padding: "8px 0px" }}
                          >
                            <Form.Label>Sort by </Form.Label>
                          </Col>
                          <Col xs={4}>
                            <Form.Control
                              onChange={(e) => {
                                onChangeDevSortField(e);
                              }}
                              as="select"
                            >
                              <option value="devid">ID</option>
                              <option value="owner">Owner</option>
                            </Form.Control>
                          </Col>
                          <Col xs={4}>
                            <Form.Control
                              onChange={(e) => {
                                onChangeDevSortValue(e);
                              }}
                              as="select"
                            >
                              <option value="asc">Ascending</option>
                              <option value="desc">Descending</option>
                            </Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>
                      <BootstrapTable
                        keyField="id"
                        data={deviceListForTable}
                        columns={columnsDevice}
                        bordered={false}
                        striped={true}
                        selectRow={selectRowDevice}
                        pagination={paginationFactory(pnoptions)}
                      />

                      <Modal
                        show={showDeleteOneDeviceModal}
                        onHide={() => {
                          setShowDeleteOneDeviceModal(false);
                        }}
                      >
                        <Modal.Header
                          closeButton
                          style={{ borderBottom: "none" }}
                        >
                          <Modal.Title>Delete device</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Row>
                              <p>
                                Are you sure want to permanently delete device {deletingDeviceRow.deviceCode} -{" "}
                                {deletingDeviceRow.deviceName}?
                                <br />
                                This action can't be undone.
                              </p>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                              <Button
                                className="normalButton"
                                onClick={() => {
                                  onClickDeleteDevice();
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                className="cancelButton"
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  setShowDeleteOneDeviceModal(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>

                      <Modal
                        show={showDeleteSelectedDevicesModal}
                        onHide={() => {
                          setShowDeleteSelectedDevicesModal(false);
                        }}
                      >
                        <Modal.Header
                          closeButton
                          style={{ borderBottom: "none" }}
                        >
                          <Modal.Title>Delete selected devices</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Container>
                            <Row>
                              <p>
                                Are you sure want to permanently delete{" "}
                                {selectedDevicesList.length} devices?
                                <br />
                                This action can't be undo.
                              </p>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                              <Button
                                className="normalButton"
                                onClick={() => {
                                  onClickDeleteSelectedDevices();
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                className="cancelButton"
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  setShowDeleteSelectedDevicesModal(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </Row>
                          </Container>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="devnotes"
                  title="Device notes"
                  tabClassName="sub_menus"
                >
                  <EditDoc tag="note" subTitle="Device notes" />
                </Tab>
                <Tab
                  eventKey="products"
                  title="Product list"
                  tabClassName="sub_menus"
                >
                  <ProductList />
                </Tab>
                <Tab eventKey="about" title="About" tabClassName="sub_menus">
                  <EditDoc tag="about" subTitle="About" />
                </Tab>
                <Tab eventKey="faq" title="FAQ" tabClassName="sub_menus">
                  <EditDoc tag="faq" subTitle="FAQ" />
                </Tab>
                <Tab
                  eventKey="terms"
                  title="Terms of Service"
                  tabClassName="sub_menus"
                >
                  <EditDoc tag="terms" subTitle="Terms of Service" />
                </Tab>
                <Tab
                  eventKey="policy"
                  title="Privacy Policy"
                  tabClassName="sub_menus"
                >
                  <EditDoc tag="policy" subTitle="Privacy Policy" />
                </Tab>
                <Tab eventKey="dump" title="Dump" tabClassName="sub_menus">
                  <Dump />
                </Tab>
                <Tab eventKey="devicetypes" title="Device Types" tabClassName="sub_menus">
                  <DeviceTypes />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
